(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/swiper/assets/javascripts/swiper.js') >= 0) return;  svs.modules.push('/components/components/swiper/assets/javascripts/swiper.js');
"use strict";


(function (svs) {
  'use strict';

  svs.components = svs.components || {};
  svs.components.Swiper = function () {
    const bodyEl = $('body');
    const swiperContainerClass = 'js-swiper-container';
    let bodyScrollPosition = null;
    let resetTimeout;
    let events = [];
    let refresh;
    function init(containerElement, options) {
      if (containerElement) {
        options = $.extend({
          scrollbars: false,
          scrollingX: false,
          scrollingY: true,
          disableOverflowScrolling: false 
        }, options);
        let $containerElement = $(containerElement);
        let $scrollElement = $('<div/>');
        const styleAttribute = svs.components.SwiperBase.getStyleAttribute(options.scrollingX, options.scrollingY, options.disableOverflowScrolling);
        const $oldScroll = $containerElement.find('.js-swiper-container');

        if ($oldScroll.length > 0) {
          if ($oldScroll.attr('style') === '') {
            $oldScroll.attr('style', styleAttribute);
          }
          return this.scroller;
        }
        $scrollElement.attr('style', styleAttribute).addClass("".concat(swiperContainerClass, " swiper-container"));
        refresh = function () {
          clearTimeout(resetTimeout);
          $scrollElement.attr('style', '-webkit-overflow-scrolling:auto; overflow:hidden;');
          resetTimeout = setTimeout(() => {
            $scrollElement.attr('style', styleAttribute);
          }, svs.components.SwiperBase.orientationRefreshTimeout);
        };

        svs.core.detect.orientation.on('change', refresh);
        $scrollElement.on('scroll', event => svs.components.SwiperBase.handleScroll(event, events, $scrollElement[0]));
        $scrollElement[0].addEventListener('touchstart', event => svs.components.SwiperBase.handleTouchStart(event, $scrollElement[0]));
        $scrollElement[0].addEventListener('touchmove', event => svs.components.SwiperBase.handleTouchMove(event, $scrollElement[0], options.scrollingX));

        while ($containerElement[0] && $containerElement[0].firstChild) {
          $scrollElement[0].appendChild($containerElement[0].firstChild);
        }
        $containerElement[0] && $containerElement[0].appendChild($scrollElement[0]);

        this.scroller = {
          refresh,
          updateDimensions() {},
          destroy(removeDOMElements) {
            events = [];
            if ($scrollElement) {
              if (removeDOMElements) {
                $scrollElement.remove();
              }
              $scrollElement.off();
              $scrollElement = null;
            }
            if ($containerElement) {
              if (removeDOMElements) {
                $containerElement.remove();
              }
              $containerElement.off();
              $containerElement = null;
            }
            clearTimeout(resetTimeout);
            svs.core.detect.orientation.off('change', refresh);
          },
          scrollTo(x, y) {
            $containerElement.find(".".concat(swiperContainerClass)).scrollLeft(x).scrollTop(y);
          },
          scrollTop() {
            $containerElement.find(".".concat(swiperContainerClass)).scrollTop(0);
          },
          addEventListener(event, callback) {
            events[event] = events[event] || [];
            events[event].push(callback);
          },
          isSimulatedScroller: true,
          hideScroll(hide) {
            if (hide) {
              $scrollElement.attr('style', '-webkit-overflow-scrolling:auto; overflow:hidden;');
            } else {
              $scrollElement.attr('style', styleAttribute);
            }
          }
        };
        return this.scroller;
      }
    }

    function disableBodyScroll() {
      if (svs.core.detect.formfactor.mobile() === true) {
        const $window = $(window);
        bodyScrollPosition = $window.scrollTop();
        $window.scrollTop(0);
        bodyEl.addClass('disable-body-scroll');
      }
    }

    function enableBodyScroll() {
      if (svs.core.detect.formfactor.mobile() === true) {
        bodyEl.removeClass('disable-body-scroll');
        if (bodyScrollPosition !== null) {
          $(window).scrollTop(bodyScrollPosition);
          bodyScrollPosition = null;
        }
      }
    }

    function dispose() {
      if (this.scroller) {
        this.scroller.destroy(true);
      }
    }
    return {
      init,
      enableBodyScroll,
      dispose,
      disableBodyScroll
    };
  };
  svs.components.swiper = new svs.components.Swiper();
})(svs);

 })(window);