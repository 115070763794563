(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/swiper/assets/javascripts/swiper-base.js') >= 0) return;  svs.modules.push('/components/components/swiper/assets/javascripts/swiper-base.js');
"use strict";

(function (svs) {
  'use strict';

  function getStyleAttribute(scrollingX, scrollingY, disableOverflowScrolling) {
    let styleAttribute = '';
    if (scrollingX) {
      styleAttribute += ' overflow-x: auto;';
    } else {
      styleAttribute += ' overflow-x: hidden;';
    }
    if (scrollingY) {
      styleAttribute += ' overflow-y: auto;';
    } else {
      styleAttribute += ' overflow-y: hidden;';
    }
    if (!disableOverflowScrolling) {
      styleAttribute += ' -webkit-overflow-scrolling: touch;';
    }
    styleAttribute += ' height: 100%;';
    return styleAttribute;
  }
  function getReactStyleAttribute(scrollingX, scrollingY, disableOverflowScrolling) {
    const styleAttribute = {};
    if (scrollingX) {
      styleAttribute.overflowX = 'auto';
    } else {
      styleAttribute.overflowX = 'hidden';
    }
    if (scrollingY) {
      styleAttribute.overflowY = 'auto';
    } else {
      styleAttribute.overflowY = 'hidden';
    }
    if (!disableOverflowScrolling) {
      styleAttribute.WebkitOverflowScrolling = 'touch';
    }
    styleAttribute.height = '100%';
    return styleAttribute;
  }
  function handleTouchStart(e, element) {
    const top = element.scrollTop;
    const totalScroll = element.scrollHeight;
    const currentScroll = top + element.offsetHeight;
    if (top === 0) {
      element.scrollTop = 1;
    } else if (currentScroll === totalScroll) {
      element.scrollTop = top - 1;
    }
  }
  function handleTouchMove(e, element, scrollingX) {
    if (scrollingX === true || element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth) {
    } else {
      e.preventDefault();
    }
  }
  function handleScroll(event, events, element) {
    const el = $(element);
    const scrollTop = el.scrollTop();
    const reachedEndEvents = events.reachedend;
    const scrollEvents = events.scroll;
    if (scrollTop === 0) {
      el[0].scrollTop = 1;
    } else if (reachedEndEvents !== undefined && reachedEndEvents.length > 0) {
      if (Math.floor(el[0].scrollHeight - scrollTop) === Math.floor(el.height())) {
        el[0].scrollTop = scrollTop - 1;
        for (let i = 0, length = reachedEndEvents.length; i < length; i++) {
          (function (index) {
            if (reachedEndEvents[index]) {
              reachedEndEvents[index].call();
            }
          })(i);
        }
      }
    }
    if (scrollEvents !== undefined && scrollEvents.length) {
      for (let j = 0, lngth = scrollEvents.length; j < lngth; j++) {
        (function (index) {
          if (scrollEvents[index]) {
            scrollEvents[index].call(this, scrollTop);
          }
        })(j);
      }
    }
  }
  svs.components = svs.components || {};
  svs.components.SwiperBase = {
    getStyleAttribute,
    getReactStyleAttribute,
    handleTouchStart,
    handleTouchMove,
    handleScroll,
    orientationRefreshTimeout: 100
  };
})(svs);

 })(window);